<template>
  <div class="login-box">
    <div class="login-logo"><b>Admin </b>FMSchool</div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Sign in to start your session</p>
        <form>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="test@wemari.co.kr"
              v-model="admin_id"
              v-on:keydown.enter="getAuthCode"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="admin_pwd"
              v-on:keydown.enter="getAuthCode"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              type="number"
              class="form-control"
              placeholder="OTP"
              v-model="otp_code"
              v-on:keydown.enter="getAuthCode"
              min="0"
              max="999999"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fab fa-google"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <!--
              <div class="icheck-primary">
                <input type="checkbox" id="remember" />
                <label for="remember">Remember Me</label>
              </div>
              -->
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block" v-on:click="getAuthCode">
                Sign In
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>
        <!--
        <p class="mb-1">
          <a href="forgot-password.html">I forgot my password</a>
        </p>
        
        <p class="mb-0">
          <a href="register.html" class="text-center"
            >Register a new membership</a
          >
        </p>
        -->
      </div>
      <template>
        <b-modal
          ref="loginCode"
          title="문자 인증번호"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <!-- <form ref="form" @submit.stop.prevent="loginCode"> -->
          <form ref="form">
            <b-form-group
              label="인증번호"
              label-for="codd-input"
              invalid-feedback="Code is required"
              :state="codeState"
            >
              <b-form-input
                id="code-input"
                v-model="code"
                :state="codeState"
                v-on:keydown.enter="handleOk"
                required
                autofocus
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
      </template>
      <!-- /.login-card-body -->
    </div>
  </div>
</template>
<script>
import { otpLogin } from '@/api/user'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      admin_id: '',
      admin_pwd: '',
      otp_code: '',
      code: '',
      codeState: null,
      login_token: ''
    }
  },
  methods: {
    ...mapActions('user', ['setUser']),
    async getAuthCode(e) {
      if (e) e.preventDefault()
      const { code, data, message } = await otpLogin(this.admin_id, this.admin_pwd, this.otp_code)
      if (code === '0000') {
        var json = {
          ADMIN_ID: this.admin_id,
          LOGIN_TOKEN: data.accessToken,
          REFRESH_TOKEN: data.refreshToken
        }
        this.setUser(json)
        this.$router.push('/')
      } else {
        alert(message)
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.code = ''
      this.codeState = null
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.codeState = valid
      return valid
    },
    async handleSubmit() {
      const { code, data, message } = await login(this.login_token, this.code)
      if (code === '0000') {
        var json = {
          ADMIN_ID: this.admin_id,
          LOGIN_TOKEN: data.accessToken,
          REFRESH_TOKEN: data.refreshToken
        }
        this.setUser(json)
        this.$router.push('/')
      } else {
        alert(message)
      }
    }
  },
  mounted() {
    this.$root.$emit('changeBodyClass', {
      add: 'login-page',
      remove: 'sidebar-mini'
    })
  }
}
</script>
